.velox-loader {
    width: 100px;
    height: 100px;
    position: relative;
}

.shadow-box{
    position: fixed;
    z-index: 900;
    background: rgb(0 0 0 / 28%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(5px);
}
.velox-loader::before,
.velox-loader::after {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    mix-blend-mode: multiply;
    animation: rotate 1s infinite cubic-bezier(0.77, 0, 0.175, 1);
}

.velox-loader::before {
    background-color: #E8730C;
}

.velox-loader::after {
    background-color: white;
    animation-delay: .5s;
}

@keyframes rotate {
    0%,
    100% {
        left: 95px;
    }
    25% {
        transform: scale(.3);
    }
    50% {
        left: 0;
    }
    75% {
        transform: scale(1);
    }
}

.velox-bar-loader {
    display: flex;
    align-items: center;
}

.bar {
    display: inline-block;
    width: 8px;
    height: 55px;
    background-color: rgba(255, 255, 255, .5);
    border-radius: 18px;
    animation: scale-up 1s linear infinite;
}

.bar:nth-child(2) {
    height: 75px;
    margin: 0 5px;
    animation-delay: .25s;
}

.bar:nth-child(3) {
    animation-delay: -5s;
}

@keyframes scale-up {
    20% {
        background-color: darkorange;
        transform: scaleY(1.5);
    }
    40% {
        transform: scaley(1)
    }
}