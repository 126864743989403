@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin:0;
  padding:0;
  font-family: 'Poppins';
  -webkit-tap-highlight-color: transparent;
}

html{
  background-color: #DEDFDD;
}

.bg-image{
  background-image: url("../public/Images/bg.png");
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}